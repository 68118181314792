import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../shared/api';
const { NODE_ENV } = process.env;

function PaymentFail(props) {
    const [theme, setTheme] = useState(null);

    const getTheme = function () {
        const pcnHostName = NODE_ENV === 'production' ? window.location.host : 'pcn.advancedparking.tech';

        Api.getTheme(
            (data) => setTheme(data),
            (error) => console.log(error),
            pcnHostName
        )
    };

    const setStyles = function () {
        var titleTheme = new Object();
        titleTheme.color = '#' + theme.managePcnTitleColour + '!important';
        titleTheme.backgroundColor = '#' + theme.managePcnTitleBackgroundColour + '!important';

        var titleStyle = "";
        for (const key in titleTheme) {
            if (titleStyle !== "") {
                titleStyle = titleStyle + '; ';
            }
            var hyphenKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
            titleStyle = titleStyle + hyphenKey + ':' + titleTheme[key];
        }
        titleStyle = titleStyle + ';'
        createStyling('.themed-title', titleStyle);

        var textTheme = new Object();
        textTheme.color = '#' + theme.managePcnTextColour + '!important';
        textTheme.backgroundColor = '#' + theme.managePcnBackgroundColour + '!important';

        var bodyStyle = "";
        for (const key in textTheme) {
            if (bodyStyle !== "") {
                bodyStyle = bodyStyle + '; ';
            }
            var hyphenKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
            bodyStyle = bodyStyle + hyphenKey + ':' + textTheme[key];
        }
        bodyStyle = bodyStyle + ';'
        createStyling('.themed-body', bodyStyle);
    };

    const createStyling = function (selector, style) {
        if (document.styleSheets !== null && document.styleSheets !== undefined) {
            var styleSheet, mediaType;

            if (document.styleSheets.length > 0) {
                for (var i = 0, l = document.styleSheets.length; i < l; i++) {
                    if (document.styleSheets[i].disabled) {
                        continue;
                    }
                    var media = document.styleSheets[i].media;
                    mediaType = typeof media;

                    if (mediaType === 'string') {
                        if (media === '' || (media.indexOf('screen') !== -1)) {
                            styleSheet = document.styleSheets[i];
                        }
                    }
                    else if (mediaType == 'object') {
                        if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
                            styleSheet = document.styleSheets[i];
                        }
                    }

                    if (typeof styleSheet !== 'undefined') {
                        break;
                    }

                }
            }

            if (typeof styleSheet === 'undefined') {
                var styleSheetElement = document.createElement('style');
                document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

                for (i = 0; i < document.styleSheets.length; i++) {
                    if (document.styleSheets[i].disabled) {
                        continue;
                    }
                    styleSheet = document.styleSheets[i];
                }

                mediaType = typeof styleSheet.media;
            }

            if (mediaType === 'string') {
                for (var i = 0, l = styleSheet.rules.length; i < l; i++) {
                    if (styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet.rules[i].style.cssText = style;
                        return;
                    }
                }

                styleSheet.addRule(selector, style);
            }
            else if (mediaType === 'object') {
                var styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
                for (var i = 0; i < styleSheetLength; i++) {
                    if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet.cssRules[i].style.cssText = style;
                        return;
                    }
                }

                styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
            }
        }
    };

    useEffect(() => {
        if (theme !== null) {
            setStyles();
        }
    }, [theme]);

    useEffect(() => {
        getTheme();
    }, []);
    return (
        <>
            <div className="container-fluid landing-page-container">
                {theme !== null && theme.managePcnLogoAttachment.url !== null &&
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <div className="org-logo-container mt-5">
                                <img src={theme.managePcnLogoAttachment.url} />
                            </div>
                        </div>
                    </div>}
                <div className="row align-items-center justify-content-center mt-4">
                    <div className="col-10 col-sm-8 justify-content-center">
                        <div className="row justify-content-center">
                            <div className="card my-3 smaller">
                                <div className="card-header themed-title">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <h5 className="mb-0 themed-title">Payment Cancelled</h5>
                                        </div>
                                        <div className="col text-right">
                                            <Link to="/" className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body themed-body">
                                    If you have outstanding balance this will still need to be paid, please enter the details of your ticket again to see your available actions.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PaymentFail;