import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Api from '../shared/api';
import moment from 'moment';
import UploadFile from '../shared/file-upload';
import Enums from '../utilities/enum';
import Loader from '../shared/loader';

const { NODE_ENV } = process.env;

function LandingPage(props) {
    const [organisationId, setOrganisationId] = useState("");
    const [pcn, setPcn] = useState(null);
    const [pcnReferenceNumber, setPcnReferenceNumber] = useState("");
    const [vrm, setVrm] = useState("");
    const [canSave, setCanSave] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [payPcn, setPayPcn] = useState(false);
    const [appealPcn, setAppealPcn] = useState(false);
    const [transferPcn, setTransferPcn] = useState(false);

    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("United Kingdom");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    const [appealReason, setAppealReason] = useState("");
    const [appealFileIdList, setAppealFileIdList] = useState([]);
    const [factsTrue, setFactsTrue] = useState(false);
    const [appealReview, setAppealReview] = useState(false);
    const [canAppeal, setCanAppeal] = useState(false);

    const [contactTypes, setContactTypes] = useState(null);
    const [appellantType, setAppellantType] = useState(null);

    //transfer
    const [files, setFiles] = useState([]);

    const [designationLiable, setDesignationLiable] = useState(null);
    const [titleLiable, setTitleLiable] = useState("");
    const [firstNameLiable, setFirstNameLiable] = useState("");
    const [lastNameLiable, setLastNameLiable] = useState("");
    const [companyNameLiable, setCompanyNameLiable] = useState("");
    const [address1Liable, setAddress1Liable] = useState("");
    const [address2Liable, setAddress2Liable] = useState("");
    const [address3Liable, setAddress3Liable] = useState("");
    const [cityLiable, setCityLiable] = useState("");
    const [postcodeLiable, setPostcodeLiable] = useState("");
    const [countryLiable, setCountryLiable] = useState("United Kingdom");
    const [contactNumberLiable, setContactNumberLiable] = useState("");
    const [emailAddressLiable, setEmailAddressLiable] = useState("");

    const [notifierAttachment, setNotifierAttachment] = useState(null);
    const [liableAttachment, setLiableAttachment] = useState(null);

    const [transferCanSave, setTransferCanSave] = useState(false);
    const [transferInReview, setTransferInReview] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [pcnPaid, setPcnPaid] = useState(false);
    const [payLoading, setPayLoading] = useState(false);

    //theme
    const [theme, setTheme] = useState(null);

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showLiable, setShowLiable] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseLiable = () => setShowLiable(false);
    const handleShowLiable = () => setShowLiable(true);
    const history = useHistory();

    const clear = function () {
        setVrm("");
        setPcnReferenceNumber("");
        setShowError(false);
        setPcn(null);
        setPcnPaid(false);
    };

    const submit = function () {
        setShowLoading(true);
        setShowError(false);
        if (organisationId !== "") {
            Api.getPcn(
                (data) => {
                    setShowLoading(false);

                    if (data.isError) {
                        setShowError(true);
                        setErrorMessage(data.errorMessage);
                    } else {
                        setShowError(false);
                        setErrorMessage(null);
                        setPcn(data);
                    }
                },
                (error) => {
                    setShowLoading(false);
                    setShowError(true);
                    setErrorMessage("Error searching for PCN, please try again.")
                },
                organisationId, pcnReferenceNumber, vrm
            )
        }
    };

    const transfer = function () {
        if (canSave) {
            setLoading(true);

            var notifier = new Object();
            notifier.firstName = firstName;
            notifier.lastName = lastName;
            notifier.companyName = companyName;
            notifier.addressLine1 = address1;
            notifier.addressLine2 = address2;
            notifier.addressLine3 = address3;
            notifier.townOrCity = city;
            notifier.postCode = postcode;
            notifier.country = country;
            notifier.mobilePhoneNumber = contactNumber;
            notifier.emailAddress = emailAddress;
            notifier.contactType = 0;
            notifier.attachmentId = (notifierAttachment && notifierAttachment !== null) ? notifierAttachment.id : null;

            var liabilityParty = new Object();
            liabilityParty.firstName = firstNameLiable;
            liabilityParty.lastName = lastNameLiable;
            liabilityParty.companyName = companyNameLiable;
            liabilityParty.addressLine1 = address1Liable;
            liabilityParty.addressLine2 = address2Liable;
            liabilityParty.addressLine3 = address3Liable;
            liabilityParty.townOrCity = cityLiable;
            liabilityParty.postCode = postcodeLiable;
            liabilityParty.country = countryLiable;
            liabilityParty.mobilePhoneNumber = contactNumberLiable;
            liabilityParty.emailAddress = emailAddressLiable;
            liabilityParty.attachmentId = (liableAttachment && liableAttachment !== null) ? liableAttachment.id : null;
            liabilityParty.contactType = designationLiable;

            if (organisationId !== "") {
                Api.transferLiability(
                    () => history.push("/transfer-success"),
                    () => {
                        setLoading(false);
                        window.scrollTo(0, 0);
                        setErrorMessage("Transfer of Liability Failed, please ensure all required fields are filled in.");
                    },
                    organisationId, pcn.id, designationLiable,
                    notifier, liabilityParty
                );
            }
        }
    };

    const appeal = function () {
        if (canAppeal) {
            setLoading(true);
            var details = new Object();
            details.firstName = firstName;
            details.lastName = lastName;
            details.companyName = companyName;
            details.addressLine1 = address1;
            details.addressLine2 = address2;
            details.addressLine3 = address3;
            details.townOrCity = city;
            details.postCode = postcode;
            details.country = country;
            details.mobilePhoneNumber = contactNumber;
            details.emailAddress = emailAddress;
            details.contactType = appellantType;

            if (organisationId !== "") {
                Api.appeal(
                    () => history.push("/appeal-success"),
                    () => {
                        setLoading(false);
                        window.scrollTo(0, 0);
                        setErrorMessage("Appeal Failed, please ensure all required fields are filled in.");
                    },
                    details, appealReason, appealFileIdList, organisationId, pcn.id
                );
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [appealReview, transferInReview]);

    const resetPcnState = function () {
        setAppealPcn(false);
        setPayPcn(false);
        setTransferPcn(false);
        setTransferInReview(false);
        setAppealReview(false);
    };

    const reset = function () {
        setTitle("");
        setFirstName("");
        setLastName("");
        setCompanyName("");
        setAddress1("");
        setAddress2("");
        setAddress3("");
        setCity("");
        setPostcode("");
        setCountry("United Kingdom");
        setAppellantType(null);
        setContactNumber("");
        setEmailAddress("");
        setFactsTrue(false);
        setLoading(false);

        setAppealReason("");
        setDesignationLiable(null);
        setTitleLiable("");
        setFirstNameLiable("");
        setLastNameLiable("");
        setCompanyNameLiable("");
        setAddress1Liable("");
        setAddress2Liable("");
        setAddress3Liable("");
        setCityLiable("");
        setPostcodeLiable("");
        setCountryLiable("United Kingdom");
        setContactNumberLiable("");
        setEmailAddressLiable("");
        setAppealFileIdList([]);
        setFiles([]);
        setLiableAttachment(null);
        setNotifierAttachment(null);
    };

    const setTypes = function (types) {
        var tempTypes = [];
        var i = 0;
        while (true) {
            if (types[i] === undefined) {
                break;
            } else {
                tempTypes.push(types[i])
                i++;
            }
        }
        setContactTypes(tempTypes);
    };

    const newFileUpload = function (file) {
        var currentFileIds = [];
        appealFileIdList.forEach(id => {
            currentFileIds.push(id);
        });

        var currentFiles = [];
        files.forEach(file => {
            currentFiles.push(file);
        });
        currentFiles.push(file);
        currentFileIds.push(file.id);
        setAppealFileIdList(currentFileIds);
        setFiles(currentFiles);
    };

    const removeFileUpload = function (index) {
        var i = 0;
        var j = 0;
        var currentFileIds = [];
        appealFileIdList.forEach(id => {
            if (i !== index) {
                currentFileIds.push(id);
            }
            i++;
        });

        var currentFiles = [];
        files.forEach(file => {
            if (j !== index) {
                currentFiles.push(file);
            }
            j++;
        });
        setAppealFileIdList(currentFileIds);
        setFiles(currentFiles);
    };

    const setStyles = function () {
        var titleTheme = new Object();
        titleTheme.color = '#' + theme.managePcnTitleColour + '!important';
        titleTheme.backgroundColor = '#' + theme.managePcnTitleBackgroundColour + '!important';

        var titleStyle = "";
        for (const key in titleTheme) {
            if (titleStyle !== "") {
                titleStyle = titleStyle + '; ';
            }
            var hyphenKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
            titleStyle = titleStyle + hyphenKey + ':' + titleTheme[key];
        }
        titleStyle = titleStyle + ';'
        createStyling('.themed-title', titleStyle);

        var textTheme = new Object();
        textTheme.color = '#' + theme.managePcnTextColour + '!important';
        textTheme.backgroundColor = '#' + theme.managePcnBackgroundColour + '!important';

        var bodyStyle = "";
        for (const key in textTheme) {
            if (bodyStyle !== "") {
                bodyStyle = bodyStyle + '; ';
            }
            var hyphenKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
            bodyStyle = bodyStyle + hyphenKey + ':' + textTheme[key];
        }
        bodyStyle = bodyStyle + ';'
        createStyling('.themed-body', bodyStyle);

        //custom css
        let selector = document.getElementById('login-card');
        var style = document.createElement('style');
        style.innerHTML = theme.managePcnCustomCss;
        if (selector !== null) {
            selector.appendChild(style);
        }
    };

    const createStyling = function (selector, style) {
        if (document.styleSheets !== null && document.styleSheets !== undefined) {
            var styleSheet, mediaType;
            if (document.styleSheets.length > 0) {
                for (var i = 0, l = document.styleSheets.length; i < l; i++) {
                    if (document.styleSheets[i].disabled) {
                        continue;
                    }
                    var media = document.styleSheets[i].media;
                    mediaType = typeof media;

                    if (mediaType === 'string') {
                        if (media === '' || (media.indexOf('screen') !== -1)) {
                            styleSheet = document.styleSheets[i];
                        }
                    }
                    else if (mediaType == 'object') {
                        if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
                            styleSheet = document.styleSheets[i];
                        }
                    }

                    if (typeof styleSheet !== 'undefined') {
                        break;
                    }

                }
            }

            if (typeof styleSheet === 'undefined') {
                var styleSheetElement = document.createElement('style');
                document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

                for (i = 0; i < document.styleSheets.length; i++) {
                    if (document.styleSheets[i].disabled) {
                        continue;
                    }
                    styleSheet = document.styleSheets[i];
                }

                mediaType = typeof styleSheet.media;
            }
            if (mediaType === 'string') {
                for (var i = 0, l = styleSheet.rules.length; i < l; i++) {
                    if (styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet.rules[i].style.cssText = style;
                        return;
                    }
                }

                styleSheet.addRule(selector, style);
            }
            else if (mediaType === 'object') {
                var styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
                for (var i = 0; i < styleSheetLength; i++) {
                    if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet.cssRules[i].style.cssText = style;
                        return;
                    }
                }

                styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
            }
        }
    };

    const redirectToPayUrl = function () {
        if (organisationId !== "") {
            setPayLoading(true);
            Api.getPayUrl(
                (data) => {
                    setPayLoading(false);
                    window.location.replace(data.url);
                },
                () => {
                    setPayLoading(false);
                    setErrorMessage("Error creating payment solution, please try again.")
                },
                pcn.id,
                organisationId
            )
        }
    };

    const getTheme = function () {
        const pcnHostName = NODE_ENV === 'production' ? window.location.host : 'cps.advancedparking.tech';

        Api.getTheme(
            (data) => setTheme(data),
            (error) => console.log(error),
            pcnHostName
        )
    };

    useEffect(() => {
        reset();
    }, [appealPcn, transferPcn]);

    useEffect(() => {
        if (theme !== null) {
            setStyles();
            setOrganisationId(theme.organisationId);
        }
    }, [theme]);

    useEffect(() => {
        if (pcnReferenceNumber !== null && pcnReferenceNumber !== "" && vrm !== null && vrm !== "") {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }, [vrm, pcnReferenceNumber]);

    useEffect(() => {
        if (appellantType !== null && firstName !== null && firstName !== "" && lastName !== null && lastName !== "" && address1 !== null && address1 !== "" && city !== null && city !== "" &&
            postcode !== null && postcode !== "" && emailAddress !== null && emailAddress !== "" && appealReason !== null && appealReason !== "" && factsTrue && canSave) {
            setCanAppeal(true);
        } else {
            setCanAppeal(false);
        }
    }, [appellantType, firstName, lastName, address1, city, postcode, emailAddress, appealReason, factsTrue, canSave, files]);

    useEffect(() => {
        if (firstName !== "" && firstName !== null && lastName !== "" && lastName !== null && address1 !== "" && address1 !== null && city !== "" && city !== null && postcode !== "" && postcode !== null && emailAddress !== "" && emailAddress !== null &&
            firstNameLiable !== "" && firstNameLiable !== null && lastNameLiable !== "" && lastNameLiable !== null && address1Liable !== "" && address1Liable !== null && cityLiable !== "" && cityLiable !== null && postcodeLiable !== "" && postcodeLiable !== null
            && emailAddressLiable !== "" && emailAddressLiable !== null && designationLiable !== null && factsTrue) {
            setTransferCanSave(true);
        } else {
            setTransferCanSave(false);
        }
    }, [firstName, lastName, address1, city, postcode, emailAddress, firstNameLiable, lastNameLiable, address1Liable, cityLiable, postcodeLiable, emailAddressLiable, factsTrue, designationLiable]);

    useEffect(() => {
        if (pcn !== null) {
            if (pcn.breachStatus === 500) {
                setPcnPaid(true);
            }
        }
    }, [pcn]);

    useEffect(() => {
        setTypes(Enums.ContactTypes);
        getTheme();
    }, []);

    return (
        <>
            {organisationId === "" && <Loader />}
            <div id="login-card" className="container-fluid landing-page-container">
                {organisationId !== "" && pcnPaid &&
                    <>
                        {theme !== null && theme.managePcnLogoAttachment !== null &&
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <div className="org-logo-container mt-3">
                                        <img src={theme.managePcnLogoAttachment.url} />
                                    </div>
                                </div>
                            </div>}
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-6">
                                <div className="row justify-content-center">
                                    <div className="card my-3">
                                        <div className="card-header themed-title">
                                            <div className="row align-items-center justify-space-between">
                                                <div className="col-auto">
                                                    <h4 className="mb-0 themed-title">Manage your PCN</h4>
                                                </div>
                                                <div className="col text-right">
                                                    <button onClick={() => clear()} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body themed-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Your PCN</h4>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-6 col-12">
                                                    <p className="font-weight-semi-bold mb-0">PCN</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{pcnReferenceNumber}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold mb-0">VRM</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{vrm}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold mb-0">Status:</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">Paid</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold mb-0">Outstanding Balance</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">£0.00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                {organisationId !== "" && !pcnPaid &&
                    <>
                        {pcn === null &&
                            <>
                                {theme !== null && theme.managePcnLogoAttachment !== null &&
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <div className="org-logo-container mt-5">
                                                <img src={theme.managePcnLogoAttachment.url} />
                                            </div>
                                        </div>
                                    </div>}
                                <div className="row align-items-center justify-content-center mt-sm-4">
                                    <div className="col-10 col-sm-8 justify-content-center">
                                        <div className="row justify-content-center">
                                            <div className="card my-3 smaller">
                                                <div className="card-header themed-title">
                                                    <div className="row align-items-center justify-content-center">
                                                        <div className="col-12 text-center">
                                                            <h3 className="mb-0 themed-title">Manage your PCN</h3>
                                                            <h5 className="mt-1 themed-title">Please provide details below to start your process</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body themed-body">
                                                    {!showLoading && <>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Parking Charge Number (PCN)*</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={pcnReferenceNumber} placeholder="e.g. AA12345" onChange={(e) => setPcnReferenceNumber(e.target.value.toUpperCase())} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Vehicle Registration Mark (VRM)*</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={vrm} placeholder="e.g. GY13AXH" onChange={(e) => setVrm(e.target.value.toUpperCase())} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                    {showLoading &&
                                                        <div className="small my-3"><Loader /></div>}
                                                    {showError && <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p className="font-weight-semi-bold text-danger text-center mt-1 mb-1" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                                            </div>
                                                        </div>
                                                    </>}
                                                </div>
                                                <div className="card-footer themed-title">
                                                    <div className="row client-edit-buttons">
                                                        <div className="col-auto ml-auto pl-0">
                                                            <div id="dashboard-actions">
                                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => clear()}>
                                                                    <i className="fas fa-times"></i> Clear</button>
                                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                                    <i className="fas fa-check"></i> Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        {pcn !== null && <>
                            {theme !== null && theme.managePcnLogoAttachment.url !== null &&
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <div className="org-logo-container mt-3">
                                            <img src={theme.managePcnLogoAttachment.url} />
                                        </div>
                                    </div>
                                </div>}
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="card my-3">
                                        <div className="card-header themed-title">
                                            <div className="row align-items-center justify-content-between">
                                                {!transferPcn &&
                                                    <>
                                                        <div className="col-7 col-sm-auto">
                                                            <h4 className="mb-0 themed-title">{!payPcn && !appealPcn && <>Manage</>}{payPcn && <>Pay</>}{appealPcn && <>Appeal</>} your PCN</h4>
                                                        </div>
                                                        <div className="col-auto text-right mt-1">
                                                            {!transferPcn && !appealPcn && !payPcn &&
                                                                <button onClick={() => clear()} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                            {transferPcn &&
                                                                <button onClick={() => { reset(); resetPcnState(); }} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                            {appealPcn &&
                                                                <button onClick={() => { reset(); resetPcnState(); }} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                            {payPcn &&
                                                                <button onClick={() => { reset(); resetPcnState(); }} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                        </div>
                                                    </>}
                                                {transferPcn && <>
                                                    <div className="col-12 col-sm-auto">
                                                        <h4 className="mb-2 themed-title">You wish to notify us as to the Liable/Responsible party</h4>
                                                    </div>
                                                    <div className="col-12 col-sm-auto text-right mt-1">
                                                        {!transferPcn && !appealPcn && !payPcn &&
                                                            <button onClick={() => clear()} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                        {transferPcn &&
                                                            <button onClick={() => { reset(); resetPcnState(); }} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                        {appealPcn &&
                                                            <button onClick={() => { reset(); resetPcnState(); }} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                        {payPcn &&
                                                            <button onClick={() => { reset(); resetPcnState(); }} className="btn btn-falcon-default btn-sm"><i className="fas fa-reply"></i> Back</button>}
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                        <div className="card-body themed-body">
                                            {errorMessage && errorMessage !== null && errorMessage.length > 0 && <div className="row">
                                                <div className="offset-md-3 col-md-6">
                                                    <p className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                                </div>
                                            </div>}
                                            {appealPcn && <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="font-weight-semi-bold mb-3">In order to appeal you will need to complete the required fields on this screen and you will be able to submit any supporting information or documents you have,
                                                            which may include photos, receipts or other evidence.<br /><br />
                                                            When your appeal is submitted it will immediately place the process on hold and your submission will be evaluated. Once your appeal has been reviewed you will receive an Appeal Response via email within 28 days,
                                                            which will outline the findings of any investigation and determine whether your appeal is successful or unsuccessful or if further information or time is required to reach a decision.</p>
                                                    </div>
                                                </div></>}

                                            {!appealPcn && <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4>Your PCN</h4>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mb-0">Parking Reference Number (PCN)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">{pcnReferenceNumber}</div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mb-0">Vehicle Registration Mark (VRM)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">{vrm}</div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mb-0">Outstanding Balance</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">£{pcn.outstandingBalance}</div>
                                                </div>
                                            </>}
                                            {!payLoading &&
                                                <>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="mb-3">Site Enforcer had reasonable belief that the above vehicle had committed the following contravention:</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12">
                                                            <p className="font-weight-semi-bold mb-0">Contravention</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">{pcn.contraventionName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mb-0">Site</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{pcn.parkingSession !== null && pcn.parkingSession.siteName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mb-0">Issue Date</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{pcn.issueDate !== null && <>{moment(pcn.issueDate).format("DD/MM/yyyy")}</>}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <h5 className="mb-3">Evidence:</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row ticket-image-row justify-space-evenly">

                                                        {(pcn.parkingSession !== null && pcn.parkingSession !== undefined && pcn.parkingSession.entry !== null && pcn.parkingSession.entry !== undefined && pcn.parkingSession.entry.motion) ?
                                                            <>
                                                                {pcn.parkingSession.entry.imageUrls.map((item) => {
                                                                    if (item.toString().search("overview") !== -1) {
                                                                        return (
                                                                            <div className='col-md-4 overview text-center'>
                                                                                <img src={item} className="py-1 pb-4 mw-100" />
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <div className='col-md-4 number-plate text-center'>
                                                                                <img src={item} className="py-1 mw-100" />
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                            </> : <>
                                                                {pcn.attachments.map((item) => {
                                                                    if (item.isImage) {
                                                                        return (
                                                                            <div className='col-md-4 text-center'>
                                                                                <img src={item.url} className="py-1 pb-4 mw-100" />
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                            </>
                                                        }

                                                    </div>
                                                </>}
                                            {appealPcn && <>
                                                <hr className="border-dashed border-bottom-0"></hr>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <h5>Appeal</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Parking Charge Number (PCN):</p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={pcnReferenceNumber} disabled={true} onChange={(e) => setPcnReferenceNumber(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Vehicle Registration Mark (VRM):</p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={vrm} disabled={true} onChange={(e) => setVrm(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                        </div>
                                        {!payPcn && !appealPcn && !transferPcn && !payLoading &&
                                            <div className="card-footer themed-title">
                                                <div className="row justify-content-center">
                                                    <button onClick={() => setAppealPcn(true)} className="btn btn-primary mx-1 my-1">Appeal your PCN</button>
                                                    <button onClick={() => setTransferPcn(true)} className="btn btn-primary mx-1 my-1">Transfer Liability</button>
                                                    <button onClick={() => { setPayPcn(true); redirectToPayUrl(); }} className="btn btn-success mx-1 my-1">Pay Outstanding Balance</button>
                                                </div>
                                            </div>}
                                        {payLoading &&
                                            <div className="card-footer themed-body">
                                                <div className="small pb-3"><Loader /></div>
                                            </div>}
                                    </div>
                                    {transferPcn && <>
                                        <div className="card my-3">
                                            <div className="card-body themed-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="font-weight-semi-bold mb-3">Please provide the necessary information so that we can address this matter with the Person(s) responsible for the recorded contravention.<br />
                                                            Please complete all required fields in full. If you are a vehicle Hire or Lease company you will need to upload the relevant documents as required by the Protection of Freedoms Act (POFA) 2012.</p>
                                                    </div>
                                                </div>
                                                <hr className="border-dashed border-bottom-0"></hr>
                                                {!transferInReview && <>
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h5>Notifier Details</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                <p className="font-weight-semi-bold mb-3">Provide your full details including a serviceable Postal Address.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3 col-12 ">
                                                                <p className="font-weight-semi-bold mb-1">Title<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-md-6 col-12 ">
                                                                <div className="form-group mb-0">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title !== "" && <>{title}</>}{title === "" && <>Select Title</>}</button>
                                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                                            <div className="bg-white py-2 rounded-soft">
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mr")}>Mr</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mrs")}>Mrs</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Miss")}>Miss</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Ms")}>Ms</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Dr")}>Dr</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">First Name<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Last Name<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3 col-12 ">
                                                                <p className="font-weight-semi-bold mb-1">Country</p>
                                                            </div>
                                                            <div className="col-md-6 col-12 ">
                                                                <div className="form-group mb-0">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country !== "" && <>{country}</>}{country === "" && <>Select Country</>}</button>
                                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                                            <div className="bg-white py-2 rounded-soft">
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("United Kingdom")}>United Kingdom</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("France")}>France</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Supporting Document</p>
                                                            </div>
                                                            {notifierAttachment !== null &&
                                                                <div className="col-12 col-md-6">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-4">
                                                                            <a href={notifierAttachment.url} target="_blank"><h6 className="m">{notifierAttachment.filename}</h6></a>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {notifierAttachment.isImage &&
                                                                                <div className="d-flex justify-content-center my-2">
                                                                                    <img src={notifierAttachment.url} className="attachment-thumb" />
                                                                                </div>}
                                                                            {!notifierAttachment.isImage && <>
                                                                                {notifierAttachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                                {notifierAttachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                                {notifierAttachment.contentType !== "application/word.doc" && notifierAttachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                            </>}
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <button className="btn btn-sm btn-danger" onClick={() => setNotifierAttachment(null)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            <div className="col-auto">
                                                                {notifierAttachment === null &&
                                                                    <button className="btn btn-falcon-default mt-1" onClick={handleShow}>
                                                                        Add Supporting Document
                                                                    </button>}
                                                            </div>
                                                        </div>
                                                        <Modal show={show} onHide={handleClose}>
                                                            <UploadFile onSuccess={(file) => setNotifierAttachment(file)} type="Document" hideDone={true} organisationId={theme.organisationId} />
                                                            <Modal.Footer>
                                                                <button className="btn btn-falcon-default mt-1" onClick={handleClose}>
                                                                    Done
                                                                </button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </>
                                                    <hr className="border-dashed border-bottom-0"></hr>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5>Liable Party Details</h5>
                                                        </div>
                                                        <div className="col-12">
                                                            <p className="font-weight-semi-bold mb-3">Provide the full details of the Liable Party including a serviceable Postal Address.</p>
                                                        </div>
                                                    </div>
                                                    {/* liable party form */}
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-3 col-12">
                                                                <p className="font-weight-semi-bold mb-1">Liable Party Designation<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-md-6 col-12 ">
                                                                {contactTypes !== null && contactTypes.map((item, i = 0) => {
                                                                    i++;
                                                                    var text = `The Liable party was/is the ` + item + ` of the vehicle`
                                                                    var checked = false;
                                                                    if (designationLiable === i - 1) {
                                                                        checked = true;
                                                                    }
                                                                    return (
                                                                        <div class="form-check mb-1">
                                                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id={i} checked={checked} onClick={() => setDesignationLiable(i - 1)} />
                                                                            <label class="form-check-label ml-3" for={i}>
                                                                                {text}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3 col-12 ">
                                                                <p className="font-weight-semi-bold mb-1">Title<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-md-6 col-12 ">
                                                                <div className="form-group mb-0">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{titleLiable !== "" && <>{titleLiable}</>}{titleLiable === "" && <>Select Title</>}</button>
                                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                                            <div className="bg-white py-2 rounded-soft">
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Mr")}>Mr</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Mrs")}>Mrs</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Miss")}>Miss</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Ms")}>Ms</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Dr")}>Dr</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">First Name<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={firstNameLiable} onChange={(e) => setFirstNameLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Last Name<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={lastNameLiable} onChange={(e) => setLastNameLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={companyNameLiable} onChange={(e) => setCompanyNameLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={address1Liable} onChange={(e) => setAddress1Liable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={address2Liable} onChange={(e) => setAddress2Liable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={address3Liable} onChange={(e) => setAddress3Liable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={cityLiable} onChange={(e) => setCityLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={postcodeLiable} onChange={(e) => setPostcodeLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3 col-12 ">
                                                                <p className="font-weight-semi-bold mb-1">Country</p>
                                                            </div>
                                                            <div className="col-md-6 col-12 ">
                                                                <div className="form-group mb-0">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{countryLiable !== "" && <>{countryLiable}</>}{countryLiable === "" && <>Select Country</>}</button>
                                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                                            <div className="bg-white py-2 rounded-soft">
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountryLiable("United Kingdom")}>United Kingdom</button>
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountryLiable("France")}>France</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={contactNumberLiable} onChange={(e) => setContactNumberLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" value={emailAddressLiable} onChange={(e) => setEmailAddressLiable(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center">
                                                            <div className="col-12 col-md-3">
                                                                <p className="font-weight-semi-bold mt-1 mb-1">Supporting Document</p>
                                                            </div>
                                                            {liableAttachment !== null &&
                                                                <div className="col-12 col-md-6">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-4">
                                                                            <a href={liableAttachment.url} target="_blank"><h6 className="m">{liableAttachment.filename}</h6></a>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {liableAttachment.isImage &&
                                                                                <div className="d-flex justify-content-center my-2">
                                                                                    <img src={liableAttachment.url} className="attachment-thumb" />
                                                                                </div>}
                                                                            {!liableAttachment.isImage && <>
                                                                                {liableAttachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                                {liableAttachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                                {liableAttachment.contentType !== "application/word.doc" && liableAttachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                            </>}
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <button className="btn btn-sm btn-danger" onClick={() => setLiableAttachment(null)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            <div className="col-auto">
                                                                {liableAttachment === null &&
                                                                    <button className="btn btn-falcon-default mt-1" onClick={handleShowLiable}>
                                                                        Add Supporting Document
                                                                    </button>}
                                                            </div>
                                                        </div>
                                                        <Modal show={showLiable} onHide={handleCloseLiable}>
                                                            <UploadFile onSuccess={(file) => setLiableAttachment(file)} type="Document" hideDone={true} organisationId={theme.organisationId} />
                                                            <Modal.Footer>
                                                                <button className="btn btn-falcon-default mt-1" onClick={handleCloseLiable}>
                                                                    Done
                                                                </button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </>
                                                    <hr className="border-dashed border-bottom-0"></hr>
                                                    <div className="row align-items-center">
                                                        <div className="col-12 col-sm-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-2" >Statement of Truth<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-auto my-1 d-flex">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0 checkbox" checked={factsTrue} type="checkbox" onChange={() => setFactsTrue(!factsTrue)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <p className="font-weight-semi-bold mb-1" onClick={() => setFactsTrue(!factsTrue)}>The Appellant acknowledges that the facts stated in this transfer of liability are true.</p>
                                                        </div>
                                                        <div className="offset-3 col-9">
                                                            <p className="font-weight-semi-bold mt-1 mb-3">If making this as a representative of a liable party you confirm that you are duly authorised by the liable party to submit this transfer of liability on their behalf.</p>
                                                        </div>
                                                    </div>
                                                </>}
                                                {transferInReview && <>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6 col-12 ">
                                                            <p className="font-weight-bold mb-3">Notifier Details</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Title</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{title}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">First Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{firstName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Last Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{lastName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Company Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{companyName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 1</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address1}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 2</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address2}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 3</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address3}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">City</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{city}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Post Code</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{postcode}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Country</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{country}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Contact Number</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{contactNumber}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Email Address</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{emailAddress}</div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Supporting Document</p>
                                                        </div>
                                                        {notifierAttachment !== null &&
                                                            <div className="col-12 col-md-6">
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <a href={notifierAttachment.url} target="_blank"><h6 className="m">{notifierAttachment.filename}</h6></a>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        {notifierAttachment.isImage &&
                                                                            <div className="d-flex justify-content-center my-2">
                                                                                <img src={notifierAttachment.url} className="attachment-thumb" />
                                                                            </div>}
                                                                        {!notifierAttachment.isImage && <>
                                                                            {notifierAttachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                            {notifierAttachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                            {notifierAttachment.contentType !== "application/word.doc" && notifierAttachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                        </>}
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                    <hr className="border-dashed border-bottom-0"></hr>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6 col-12 ">
                                                            <p className="font-weight-bold mb-0">Liable Party Details</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Liable Party Designation</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{Enums.ContactTypes[designationLiable]}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Title</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{titleLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">First Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{firstNameLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Last Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{lastNameLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Company Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{companyNameLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 1</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address1Liable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 2</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address2Liable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 3</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address3Liable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">City</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{cityLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Post Code</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{postcodeLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Country</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{countryLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Contact Number</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{contactNumberLiable}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Email Address</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{emailAddressLiable}</div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Supporting Document</p>
                                                        </div>
                                                        {liableAttachment !== null &&
                                                            <div className="col-12 col-md-6">
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <a href={liableAttachment.url} target="_blank"><h6 className="m">{liableAttachment.filename}</h6></a>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        {liableAttachment.isImage &&
                                                                            <div className="d-flex justify-content-center my-2">
                                                                                <img src={liableAttachment.url} className="attachment-thumb" />
                                                                            </div>}
                                                                        {!liableAttachment.isImage && <>
                                                                            {liableAttachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                            {liableAttachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                            {liableAttachment.contentType !== "application/word.doc" && liableAttachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                        </>}
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </>}
                                            </div>
                                            {loading &&
                                                <div className="card-footer themed-body">
                                                    <div className="small pb-3"><Loader /></div>
                                                </div>}
                                            {!loading &&
                                                <div className="card-footer themed-title">
                                                    <div className="row justify-content-center">
                                                        <button type="button" className="btn btn-falcon-default btn-sm m-1" onClick={() => { reset(); resetPcnState(); }}>
                                                            <i className="fas fa-reply"></i> Cancel</button>
                                                        {!transferInReview && <>
                                                            <button type="button" className="btn btn-primary btn-sm m-1" disabled={!transferCanSave} onClick={() => setTransferInReview(true)}>
                                                                <i className="fas fa-eye"></i> Review</button></>}
                                                        {transferInReview && <><button type="button" className="btn btn-primary btn-sm m-1" disabled={!transferCanSave} onClick={() => setTransferInReview(false)}>
                                                            <i className="fas fa-edit"></i> Edit</button>
                                                            <button type="button" className="btn btn-success btn-sm m-1" disabled={!transferCanSave} onClick={() => transfer()}>
                                                                <i className="fas fa-check"></i> Submit</button></>}
                                                    </div>
                                                </div>}
                                        </div>
                                    </>}
                                    {appealPcn && <>
                                        {!appealReview && <>
                                            <div className="card my-3">
                                                <div className="card-header themed-title">
                                                    <div className="row align-items-center justify-space-between">
                                                        <div className="col">
                                                            <h4 className="mb-0 themed-title">
                                                                Appellant Details
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body themed-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="font-weight-bold mb-3">Please complete all required fields in full in order to begin the appeals process.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mb-1">Liable Party Designation<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            {contactTypes !== null && contactTypes.map((item, i = 0) => {
                                                                i++;
                                                                var text = `When the contravention occurred I was the vehicle's ` + item;
                                                                var checked = false;
                                                                if (appellantType === i - 1) {
                                                                    checked = true;
                                                                }
                                                                return (
                                                                    <div class="form-check mb-1">
                                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id={i} checked={checked} onClick={() => setAppellantType(i - 1)} />
                                                                        <label class="form-check-label ml-3" for={i}>
                                                                            {text}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mb-1">Title<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title !== "" && <>{title}</>}{title === "" && <>Select Title</>}</button>
                                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                                        <div className="bg-white py-2 rounded-soft">
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mr")}>Mr</button>
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mrs")}>Mrs</button>
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Miss")}>Miss</button>
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Ms")}>Ms</button>
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Dr")}>Dr</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">First Name<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Last Name<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mb-1">Country</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country !== "" && <>{country}</>}{country === "" && <>Select Country</>}</button>
                                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                                        <div className="bg-white py-2 rounded-soft">
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("United Kingdom")}>United Kingdom</button>
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("France")}>France</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card my-3">
                                                <div className="card-header themed-title">
                                                    <div className="row align-items-center justify-space-between">
                                                        <div className="col">
                                                            <h4 className="mb-0 themed-title">
                                                                Appeal Details
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body themed-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h4>Your Reason for appealing the Charge Notice</h4>
                                                            <p className="font-weight-semi-bold mb-3">Please complete the following screen, outlining the reason for your appeal. Please provide as much information as possible that will help our team to
                                                                investigate the matter and attach any evidence you may have that supports your case. When supplying attachments we accept photographs in JPEG format and will accept documents in either scanned pdf format
                                                                or word documents. We cannot accept zip files.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Appeal Reason<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group mb-1">
                                                                <textarea rows={8} className="form-control mb-0" type="text" value={appealReason} onChange={(e) => setAppealReason(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mb-0">Supporting Documents</p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="row align-items-center">
                                                                {files.length !== 0 &&
                                                                    <div className="col-md-9 col-12 ">
                                                                        {files.map((file, i = 0) => {
                                                                            i++;
                                                                            return (
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <a href={file.url} target="_blank"><h6 className="m">{file.filename}</h6></a>
                                                                                    </div>
                                                                                    <div className="col-5">
                                                                                        {file.isImage &&
                                                                                            <div className="d-flex justify-content-center my-2">
                                                                                                <img src={file.url} className="attachment-thumb" />
                                                                                            </div>}
                                                                                        {!file.isImage && <>
                                                                                            {file.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                                            {file.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                                            {file.contentType !== "application/word.doc" && file.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                                        </>}
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <button className="btn btn-sm btn-danger" onClick={() => removeFileUpload(i - 1)}>Remove</button>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>}
                                                                <div className="col-auto">
                                                                    <button className="btn btn-falcon-default my-3" data-toggle="modal" data-target="#add-new-photos">Add supporting documents</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal fade save-dialog success-dialog" id="add-new-photos" tabIndex="-1" role="dialog">
                                                            <UploadFile onSuccess={(file) => newFileUpload(file)} type="documents" organisationId={theme.organisationId} />
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-12 col-sm-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-2" >Statement of Truth<span className="req"></span></p>
                                                        </div>
                                                        <div className="col-auto my-1 d-flex">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0 checkbox" checked={factsTrue} type="checkbox" onChange={() => setFactsTrue(!factsTrue)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <p className="font-weight-semi-bold mb-1" onClick={() => setFactsTrue(!factsTrue)}>The appellant acknowledges that the facts stated in this appeal are true</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {appealReview && <>
                                            <div className="card mb-3">
                                                <div className="card-header themed-title">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h4 className="mb-0 themed-title">
                                                                Review Details
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body themed-body">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12 ">
                                                            <p className="font-weight-bold mb-3">Appellant Details</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Liable Party Designation</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{Enums.ContactTypes[appellantType]}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Title</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{title}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">First Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{firstName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Last Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{lastName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Company Name</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{companyName}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 1</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address1}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 2</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address2}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Address Line 3</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{address3}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">City</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{city}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Post Code</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{postcode}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Country</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{country}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Contact Number</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{contactNumber}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold  mb-0">Email Address</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">{emailAddress}</div>
                                                    </div>
                                                    <hr className="border-dashed border-bottom-0"></hr>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mb-0">Appeal Reason</p>
                                                        </div>
                                                        <div className="col-6">{appealReason}</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-3">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Supporting Documents</p>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="row align-items-center">
                                                                {files.length !== 0 &&
                                                                    <div className="col-md-9 col-12 ">
                                                                        {files.map((file, i = 0) => {
                                                                            i++;
                                                                            return (
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <a href={file.url} target="_blank"><h6 className="m">{file.filename}</h6></a>
                                                                                    </div>
                                                                                    <div className="col-5">
                                                                                        {file.isImage &&
                                                                                            <div className="d-flex justify-content-center my-2">
                                                                                                <img src={file.url} className="attachment-thumb" />
                                                                                            </div>}
                                                                                        {!file.isImage && <>
                                                                                            {file.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                                            {file.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                                            {file.contentType !== "application/word.doc" && file.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                                        </>}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <div className="card mb-3">
                                            {loading &&
                                                <div className="card-footer themed-body">
                                                    <div className="small pb-3"><Loader /></div>
                                                </div>}
                                            {!loading &&
                                                <div className="card-footer themed-title">
                                                    <div className="row justify-content-center">
                                                        <button type="button" className="btn btn-falcon-default mx-1 btn-sm my-1" onClick={() => { reset(); setAppealPcn(false); setAppealReview(false); }}><i className="fas fa-reply"></i> Cancel</button>
                                                        {appealReview &&
                                                            <>
                                                                <button onClick={() => setAppealReview(false)} className="btn btn-sm btn-falcon-default mx-1 my-1"><i className="fas fa-edit"></i> Edit Details</button>
                                                                <button disabled={!canAppeal} onClick={() => appeal()} className="btn btn-sm btn-success mx-1 my-1"><i className="fas fa-check"></i> Submit Appeal</button>
                                                            </>}
                                                        {!appealReview && <button disabled={!canAppeal} onClick={() => setAppealReview(true)} className="btn btn-sm btn-primary mx-1 my-1"><i className="fas fa-eye"></i> Review</button>}
                                                    </div>
                                                </div>}
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </>}
                    </>}
            </div>
            {organisationId === "" && <Loader />}
        </>
    )
}
export default LandingPage;