import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './components/landing/landing-page';
import PaymentSuccess from './components/payment/paymentSuccess';
import PaymentFail from './components/payment/paymentFail';
import AppealSuccess from './components/appeal/appealSuccess';
import TransferSuccess from './components/transfer/transferSuccess';
import FooterLogo from './components/shared/footerLogo';
import TitleHeader from './components/shared/helmet';

function App() {
  return (
    <>
    <TitleHeader />
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => <LandingPage {...props} />} />
          <Route exact path="/success" render={(props) => <PaymentSuccess {...props} />} />
          <Route exact path="/cancel" render={(props) => <PaymentFail {...props} />} />
          <Route exact path="/appeal-success" render={(props) => <AppealSuccess {...props} />} />
          <Route exact path="/transfer-success" render={(props) => <TransferSuccess {...props} />} />
        </Switch>
      </Router>
      <FooterLogo />
    </>
  );
}

export default App;
