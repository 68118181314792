import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from '../shared/api';
const { NODE_ENV } = process.env;

function TitleHeader() {
    const [orgName, setOrgName] = useState("Manage your PCN");

    useEffect(() => {
        const pcnHostName = NODE_ENV === 'production' ? window.location.host : 'pcn.advancedparking.tech';

        Api.getTheme(
            (data) => setOrgName("Manage your PCN | " + data.organisationName),
            () => setOrgName("Manage your PCN"),
            pcnHostName,
        )
    }, []);

    return (
        <div className="application">
            <Helmet>
                <title>{orgName}</title>
            </Helmet>
        </div>
    );
}

export default TitleHeader;