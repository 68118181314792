const baseUrl = "https://api.advancedparking.tech"
export default {
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    },

    getPcn(onSuccess, onError, orgId, referenceNumber, vrm) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "referenceNumber": referenceNumber,
            "vrm": vrm,
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(baseUrl + '/' + orgId + "/manage-pcn/find", requestOptions)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    transferLiability(onSuccess, onError, orgId, breachId, contactType, notifier, liableParty) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            // "contactType": contactType,
            "notifier": notifier,
            "liabilityParty": liableParty,
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(baseUrl + '/' + orgId + "/manage-pcn/" + breachId + "/transfer-liability", requestOptions)
            // .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    appeal(onSuccess, onError, appealantDetails, appealReason, attachmentIds, orgId, breachId){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "appealantDetails": appealantDetails,
            "appealReason": appealReason,
            "attachmentIds": attachmentIds,
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(baseUrl + '/' + orgId + "/manage-pcn/" + breachId + "/appeal", requestOptions)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    getPayUrl(onSuccess, onError, breachId, orgId) {
        fetch(baseUrl + '/' + orgId + '/manage-pcn/' + breachId + '/pay')
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    getTheme(onSuccess, onError, hostName) {
        fetch(baseUrl + '/manage-pcn/organisation/' + hostName)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },
}