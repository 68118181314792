import React from 'react';

function FooterLogo() {
    return (
        <>
            <div className="footer-logo-container">
                <a className="button-no-style" href="https://www.advancedparking.tech" target="_blank">
                    <img src="/assets/img/apt-logos/logo.svg" alt="" />
                </a>
            </div>
        </>
    )
}

export default FooterLogo;